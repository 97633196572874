import FirstScreen from "~/components/Enga/Components/FirstScreen";
import AutopromoContainer from "~/components/Enga/Components/AutopromoContainer";
import imgProxy from "~/utils/imgproxy";
import { urlRs } from "~/utils/url";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";

import "./EngaPages.css";
import "./EngaSinglePage.css";

import IconArrowForward from "~/img/icons/arrow_forward.svg";
import IconPlayArrow from "~/img/icons/play_arrow.svg";
import GtmVars from "../shared/Trackers/GtmVars";

export default function Enga9Page() {
  const gtmValues = () => {
    return {
      "all.pageType": "other",
      "all.mainCategory": "Engagement 9",
      "all.category": "Non défini",
      "all.subCategory": "Non défini",
      "all.subsubCategory": "Non défini",
      "all.subsubsubCategory": "Non défini",
      template: "nos-engagements",
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />
      <article class="page-engagement page-engagement-9">
        <FirstScreen
          visual="/images/engagements/page-engagement-9-visual.jpg"
          visualMobile="/images/engagements/page-engagement-9-visual-mobile.jpg"
          title="Des bâtiments plus sobres : émissions de CO<sub>2</sub> réduites, économies d’énergie."
          prevLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/qualite-et-perennite-des-constructions.html"
          nextLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/economiser-l-eau.html"
        />

        <EngaDetails />
        <section class="engagement-rows">
          <h2>Les preuves de nos engagements</h2>
          <EngaRow1 />
          <EngaRow2 />
          <EngaRow3 />
        </section>
        <VideoExcerpt />

        <section class="next-nav">
          <a
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/economiser-l-eau.html",
            )}
            class="btn"
          >
            Voir l'engagement suivant{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </section>
      </article>

      <AutopromoContainer />
    </>
  );
}

function EngaDetails() {
  return (
    <>
      <section class="engagement-details">
        <div class="introduction">
          <div class="tags">
            <span>Pour l’environnement</span>
          </div>
          <p>
            Construire des biens qui font du bien, c’est construire des
            bâtiments plus économes en énergie, plus respectueux de leur
            environnement et de la biodiversité, et en choisissant
            méticuleusement des matériaux durables.
          </p>
        </div>
      </section>
    </>
  );
}

function EngaRow1() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Tirer le meilleur parti de la réglementation</h3>
          <p>
            Cogedim s’est fixé comme objectif de réduire l’impact carbone et
            énergétique de ses constructions en tirant le meilleur parti de la
            réglementation RE 2020 dans ses projets avec des solutions ciblées
            et pertinentes.
          </p>
          <p>La RE 2020 en 3 objectifs&nbsp;:</p>
          <ul>
            <li>
              Encourager la sobriété et l’efficacité énergétique&nbsp;: pour des
              constructions qui consomment moins et utilisent des énergies moins
              carbonées&nbsp;;
            </li>
            <li>
              Diminuer l’impact carbone sur le cycle de vie des bâtiments
              neufs&nbsp;: inciter à l’usage d’énergies renouvelables et de
              matériaux biosourcés&nbsp;;
            </li>
            <li>
              Garantir la fraîcheur des bâtiments en cas de forte chaleur&nbsp;:
              pour des logements adaptés aux conditions climatiques futures.
            </li>
          </ul>
          <p class="excerpt">
            <strong>Des économies</strong> d’énergie sur l’ensemble du cycle de
            vie du bâtiment
          </p>
        </div>
        <picture class="visual-part white-bg">
          <img
            src="/images/engagements/logo-re2020.png"
            alt="RE 2020"
            width="300"
            height="97"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}

function EngaRow2() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>
            Des émissions de CO<sub>2</sub> réduites
          </h3>
          <p>
            Cogedim vise à maîtriser les émissions de CO<sub>2</sub> grâce à une
            conception optimisée et des systèmes constructibles vertueux.
          </p>
          <p>
            Lorsque cela est possible, Cogedim utilise des matériaux dont la
            fabrication et l'acheminement sont moins émissifs en CO<sub>2</sub>,
            tels que le béton bas carbone, le bois et les matériaux biosourcés.
          </p>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-9-visuel1.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}

function EngaRow3() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Des bâtiments plus économiques en énergies</h3>
          <p>
            Cogedim parvient à proposer des immeubles plus économes en énergies
            grâce à une conception optimisée des bâtiments, et en intégrant dans
            le mix énergétique des énergies renouvelables (réseaux de chaleur
            vertueux, photovoltaique, géothermie).
          </p>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-9-visuel2.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}

function VideoExcerpt() {
  const [, { setMediaOverlay }] = useMediaOverlayContext();

  return (
    <>
      <section class="video-excerpt">
        <div class="video-row">
          <div class="visual">
            <img
              src={imgProxy(
                "/images/engagements/page-engagement-9-excerpt-video.jpg",
                `size:674:338/resizing_type:fill`,
              )}
              height="188"
              width="375"
              alt=""
            />
            <button
              class="btn btn-icon"
              onClick={() => {
                setMediaOverlay(
                  "embed",
                  `<iframe height="100%" width="100%" src="https://www.youtube.com/embed/d-XYui-OPzQ" allowfullscreen />`,
                );
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconPlayArrow />
              </i>
            </button>
          </div>
          <div class="video-details">
            <h4>
              Cogedim et Altarea s’engagent dans la lutte contre le dérèglement
              climatique et l’adaptation à ses effets.
            </h4>
            <p>
              Pour atténuer notre impact lors de la construction et s’adapter au
              changement climatique.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
