import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import Enga9Page from "~/components/Enga/Enga9Page";

export default function () {
  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Construire des biens qui font du bien, c’est oeuvrer pour une meilleure réintégration de la biodiversité en développant des projets où 100% des espaces extérieurs sont éco-aménagés.",
    },
  };

  return (
    <>
      <Title>Engagement Cogedim - Des bâtiments plus sobres</Title>
      <MetaTags tags={[metaDesc]} />
      <Enga9Page />
    </>
  );
}
